.company-logos{
    width: 30%;
    height: auto;
}

@media (max-width: 768px) {
    .company-logos{
        width: 0%;
        /* width: 30%;
        height: auto;
        margin-block: 3px; */
    }
}
