.target-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 5px;
}

.target-card {
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2);
    width: 75%;
    min-width: 300px;
    margin-block: 2px;
}

.target-title-name-section {
    padding-inline: 10px;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    width: 80%;
    min-width: 160px;
    justify-items: flex-start;
}

.name-symbol {
    font-size: 16px;
    /* font-weight: bold; */
    align-self: flex-start;
}

.target-select-to-compare-section {
    padding-left: 5px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    background-color: #ffffff00;
    border-radius: 10px;
    width: 20%;
    min-width: 50px;
}

.target-price-number-section {
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff00;
    border-radius: 10px;
    width: 50%;
    min-width: 50px;
    align-items: center;
    justify-content: center;
}

.target-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4a10aa;
    margin-top: -5px;
}

.new-price {
    font-size: 18px;
    font-weight: 800;
    margin-top: -5px;
    margin-bottom: 0px;
}

.icon {
    cursor: pointer;
    color: #4a10aa;
    margin-left: 10px;
}

.content-modal {
    background-color: #f0f0f0;
    width: 64%;
    padding: 2px;
    border-radius: 10px;
    margin-top: 0px;
}

.target-price-text {
    font-size: 9px;
    color: #444;
}

.select-text {
    font-size: 10px;
    color: #4a10aa;
    /* color: #444; */
    cursor: pointer;
}

.selected-text {
    font-size: 10px;
    font-weight: bold;
    color: rgb(15, 163, 15);
    cursor: pointer;
}

.targets-logo-area{
    display:flex; 
    width:200%; 
    min-width:200px;
}



@media (max-width: 768px) {
    .target-card {
        padding: 2px;
        margin-left: 1%;
        width: 90%;
        margin-block: 2px;
    }

    .target-title-name-section {
        width: 40%;
        min-width: 100px;
    }
    
    .name-symbol {
        font-size: 14px;
    }
    
    .target-select-to-compare-section {
        padding-left: 1px;
        width: 10%;
        min-width: 40px;
    }
    
    .target-price-number-section {
        padding-left: 5px;
        border-radius: 10px;
        width: 10%;
    }
    .select-text {
        font-size: 8px;
    }
    .selected-text {
        font-size: 8px;
    }
    .new-price {
        font-size: 13px;
        font-weight: 700;
        margin-top: -7px;
    }
    .targets-logo-area{
        width:0%; 
        min-width:0px;
    }

}